import "cookieconsent"

(function() {
  window.addEventListener("load", function() {
    var lang = document.querySelector("html").getAttribute("lang");
    var content = {
      message: "",
      dismiss: "OK",
      link: "",
      href: "/privacy-policy"
    };
    if (lang.startsWith("en")) {
      content.message =
        "By using our services, you agree that we can use cookies.";
      content.link = "Privacy Policy";
    } else {
      content.message =
        "Mit der Nutzung unserer Dienste erklären Sie sich damit einverstanden, dass wir Cookies verwenden.";
      content.link = "Datenschutz";
    }
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#eaf7f7",
          text: "#5c7291"
        },
        button: {
          background: "#56cbdb",
          text: "#ffffff"
        }
      },
      theme: "classic",
      position: "bottom-right",
      content: content
    });
  });
})();
